"use client";

import { useTranslation } from "@/lib/i18n";
import React, { useEffect } from "react";

const TitleComponent: React.FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t("home.title");
  });
  return <></>;
};

export default TitleComponent;
