"use client";

import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";

export function ThemeSwitcher(props:{
  iconSize?:number
}) {
  const {iconSize=16}=props
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  if (theme === "light") {
    return <Sun size={iconSize} onClick={() => setTheme("dark")} />;
  }

  return <Moon size={iconSize} onClick={() => setTheme("light")} />;
}
