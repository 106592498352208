"use client";

import { useTranslation } from "@/lib/i18n";
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { LogOut, Settings } from "lucide-react";
import { signOut, useSession } from "next-auth/react";
import Link from "next/link";
import React from "react";

const UserDropdown: React.FC = () => {
  const { data: session } = useSession();
  const { t } = useTranslation({ ns: "nav-bar" });

  return (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <Avatar
          size="sm"
          className="cursor-pointer"
          name={session?.user?.name || ""}
          src={session?.user?.image || ""}
        />
      </DropdownTrigger>

      <DropdownMenu color="primary" variant="bordered">
        <DropdownItem key="settings" startContent={<Settings size={16} />}>
          <Link href="/settings" className="block h-full w-full">
            {t("settings")}
          </Link>
        </DropdownItem>

        <DropdownItem
          key="logout"
          color="danger"
          startContent={<LogOut size={16} />}
          onClick={() => signOut()}
        >
          {t("logOut")}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserDropdown;
