"use client";

import UserDropdown from "@/app/_components/UserDropdown";
import { ThemeSwitcher } from "@/components/ThemeSwitcher";
import { getLanguage, useTranslation } from "@/lib/i18n";
import { cn } from "@/lib/utils";
import { HelpCircle, Home, Mail, Settings } from "lucide-react";
import { useSession } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useMemo } from "react";
import LogoFile from "../../../public/logo/talencat-logo.jpg";

const Logo = <Image src={LogoFile} alt="TalenCat Logo" height={24} />;

enum TabType {
  Home = "home",
  CoverLetters = "coverletters",
  Settings = "settings",
}

const NavBar: React.FC = () => {
  const { t } = useTranslation({ ns: "nav-bar" });
  const pathname = usePathname();
  const language = getLanguage();
  const { data: session } = useSession();

  const menuItems = useMemo(
    () => [
      {
        label: (
          <>
            <Home size={16} />
            <p>{t("resumes")}</p>
          </>
        ),
        href: "/home",
      },
      {
        label: (
          <>
            <Mail size={16} />
            <p>Cover Letters</p>
          </>
        ),
        href: "/coverletters",
        hidden: language !== "en",
      },
    ],
    [language, t],
  );

  const extraMenuItems = useMemo(
    () => [
      {
        label: (
          <>
            <Mail size={18} />
            <p>{t("feedback")}</p>
          </>
        ),
        href: "mailto:support@talencat.com",
        linkProps: {
          target: "_blank",
        },
      },
      {
        label: (
          <>
            <HelpCircle size={18} />
            <p>{t("helpDocs")}</p>
          </>
        ),
        href: "https://docs.talencat.com",
        linkProps: {
          target: "_blank",
        },
      },
    ],
    [],
  );

  const systemMenuItems = useMemo(
    () => [
      {
        label: (
          <>
            <Settings size={16} />
            <p>{t("settings")}</p>
          </>
        ),
        href: "/settings",
      },
    ],
    [t],
  );

  return (
    <div className=" relative h-full w-56 shrink-0 p-5">
      <div className="flex items-center gap-2">
        {Logo}
        <p className="text-sm">TalenCat CV Maker</p>
      </div>

      <div className="mt-5 flex flex-col gap-2">
        {menuItems.map(({ label, href, hidden }) =>
          hidden ? null : (
            <Link href={href} key={href} data-disable-nprogress={true}>
              <div
                className={cn(
                  "flex items-center gap-2 rounded-md p-2 text-sm font-normal text-neutral-700 transition-colors duration-200 ",
                  "dark:text-neutral-100",
                  "border border-transparent",
                  "hover:border-neutral-200 hover:bg-neutral-50 dark:hover:border-default-300 dark:hover:bg-default",
                  pathname === href &&
                    "border-neutral-200 bg-neutral-50 dark:border-default-300 dark:bg-default",
                )}
              >
                {label}
              </div>
            </Link>
          ),
        )}
      </div>

      <div className="mt-5 flex flex-col gap-2 border-t border-neutral-200 pt-5 dark:border-neutral-800">
        {extraMenuItems.map(({ label, href, linkProps }) => (
          <Link
            href={href}
            key={href}
            data-disable-nprogress={true}
            {...linkProps}
          >
            <div
              className={cn(
                "flex items-center gap-2 rounded-md p-2 text-sm font-normal text-neutral-700 transition-colors duration-200 ",
                "dark:text-neutral-100",
                "border border-transparent",
                "hover:border-neutral-200 hover:bg-neutral-50 dark:hover:border-default-300 dark:hover:bg-default",
              )}
            >
              {label}
            </div>
          </Link>
        ))}
      </div>

      <div className="mt-5 flex flex-col gap-2 border-t border-neutral-200 pt-5 dark:border-neutral-800">
        {systemMenuItems.map(({ label, href }) => (
          <Link href={href} key={href} data-disable-nprogress={true}>
            <div
              className={cn(
                "flex items-center gap-2 rounded-md p-2 text-sm font-normal text-neutral-700 transition-colors duration-200 ",
                "dark:text-neutral-100",
                "border border-transparent",
                "hover:border-neutral-200 hover:bg-neutral-50 dark:hover:border-default-300 dark:hover:bg-default",
                pathname === href &&
                  "border-neutral-200 bg-neutral-50 dark:border-default-300 dark:bg-default",
              )}
            >
              {label}
            </div>
          </Link>
        ))}
      </div>

      <div className="absolute bottom-5 left-5 right-5 flex items-center justify-between p-2">
        <div className="flex items-center gap-2">
          <UserDropdown />
          <p className="text-sm">{session?.user?.name}</p>
        </div>

        <div className=" cursor-pointer">
          <ThemeSwitcher iconSize={18} />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
